<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  ></loading>
  <!-- <Header></Header>
  <Menu></Menu> -->
  <div class="page-wrapper">
    <div class="container-fluid position-relative">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center"></div>
        <div class="col-md-7 align-self-center text-end">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb justify-content-end">
              <li class="fw-bold h3"><span v-if="!this.user.tab.includes('admin')">
                Mon solde
              </span>
            <span v-else>Chiffre d'affaire</span>
          </li>
            </ol>
          </div>
        </div>
      </div>
    </div>


<div class="card w-50 mx-auto">
  <div class="card-header p-5">
  <h1 v-if="this.user.tab.includes('admin')">
     Chiffre d'affaire
  </h1>
  <h1 v-else>
    Mon solde
 </h1>
  <h1 class="p-2" v-if="!this.user.tab.includes('admin')">{{moneyFormat.format(list_user.solde)}} XOF</h1>
  <h1 class="p-2" v-if="this.user.tab.includes('admin')">
    {{moneyFormat.format(chiffreAffaire)}} XOF
  </h1>
  </div>
</div>
  </div>
</template>
<script>
import axios from "axios";
import "vue-loading-overlay/dist/vue-loading.css";
import { lien } from "/src/assets/api.js";

export default {
  name: "Solde",
  data() {
    return {
     user:this.$store.state.user,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      list_user:{},
      soldeInvest:"",
      fullPage: "",
      chiffreAffaire:"",
    };
  },
  methods:{
    seeSoldeInvest(){
      axios.get(lien +"/api/gain-invest")
    .then(response=>{
      this.soldeInvest = response.data.total
      
    })
    },
    seeChiffreAffaireInvest(){
      axios.get(lien +'/api/benefs')
    .then(response=>{
     
      this.chiffreAffaire = response.data.data
      
    })
    },
    getListUser(){
      axios
      .get(lien+"/api/users")

      .then((res) => {
        for (let user of res.data.data){
          if(user.id == this.$store.state.user.id){
            this.list_user = user
          }
        }
        this.isLoading = false
        
      });
    }
  },
  created(){
    this.seeSoldeInvest();
   this.getListUser()
   this.seeChiffreAffaireInvest()
  }
};
</script>
<style scoped>
*{
font-weight: bold;
}
.card-header{
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.142);
  border-radius: 10px;
}



.my_footer{
position:absolute;
width:100%;
bottom:0;
margin-left: 0 !important;
}


</style>

